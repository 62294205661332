<template>
    <div class="announcement">
        <div style="height: 100%;overflow: hidden;">
            <transition name="slide">
                <div class="news" v-if="single.content" :class="{'text-danger':single.imp}">
                    <span >
                        <i class="el-icon-bell"></i> {{single.content}}
                    </span>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    export default {
        name: "announcement",
        data() {
            return {
                news: [],
                single: {}
            }
        },
        props: {
            pad: {
                type: Boolean, default() {
                    return false;
                }
            }
        },
        computed: {
        },
        methods: {
            loadAnnouncement() {
                this.$api('Announcement.newest', {}).then(res => {
                    if (res.data.success) {
                        this.news = res.data.data;
                        if (this.news.length) {
                            this.single = this.news[0];

                            this.single.imp = this.single.important === '1';
                        }
                    }
                });
            },
            setSingle() {
                if (this.news.length > 1) {
                    const a = this.news.shift();
                    this.single = {};
                    const vm = this;
                    setTimeout(function () {
                        vm.news.push(a);
                        vm.single = vm.news[0];
                        vm.single.imp = vm.single.important === '1';
                    }, 500);
                } else if (this.news.length > 0) {
                    this.single = this.news[0];
                    this.single.imp = this.single.important === '1';
                }
            }
        },
        mounted() {
            this.loadAnnouncement();
            const vm = this;

            setInterval(function () {
                if (vm.news.length > 1) {
                    vm.setSingle();
                }
            }, 10000);

        }
    }
</script>

<style scoped lang="scss">
    .pad-announcement {
        padding: 0 25px;

    }

    .announcement {
        margin-right: 10px;
        font-size: 13px;
        color: #666;
        line-height: 30px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .slide-enter-active, .slide-leave-active {
        transition: all .5s ease-in;
    }

    .slide-leave-to /* .fade-leave-active below version 2.1.8 */
    {
        transform: translateY(-24px);
        opacity: 0;
    }

    .slide-enter {
        transform: translateY(48px);
        opacity: 0;
    }
</style>
