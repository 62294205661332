<template><!--mobile -->
    <el-row class="mobile-home-wrapper">
        <el-col :span="24" class="home-header">
            <el-col :span="10" class="logo" :class="collapsed?'logo-collapse-width':'logo-width'"
                    style="border-right:1px solid #dddddd">
                <img src="../../../src/assets/images/applogo.png" alt="applog" v-show="!collapsed"
                     style="height:40px"/>
                <span class="ml-2" v-show="!collapsed">{{ preset.app.short || "腾讯会议管理平台" }} </span>
            </el-col>
            <el-col :xs="1" :span="10" class="title">
                <div class="tools" @click.prevent="collapse">
                    <i :class="collapsed?'el-icon-s-unfold':'el-icon-s-fold'"></i>
                </div>

            </el-col>
            <div class="userinfo ">
                <div class="userinfo-name">{{ preset.app.name }}</div>
            </div>
        </el-col>
        <el-col :span="24" class="main">
            <aside :class="collapsed?'menu-collapsed':'menu-expanded'" style="position: absolute;z-index: 99">
                <!--导航菜单-->
                <el-menu
                    :default-active="$route.path"
                    @select="handleSelect"
                    class="el-menu-vertical-dark"
                    :collapse="collapsed"
                    unique-opened router
                >
                    <template v-for="(item) in menu">
                        <el-submenu :index="item.name" v-if="item.children" :key="item.name">
                            <template slot="title"><i :class="item.icon"></i>
                                <span slot="title">{{ item.name }}</span>
                            </template>
                            <el-menu-item v-for="child in item.children" :index="child.path" :key="child.name">
                                <i v-if="child.icon" :class="child.icon"></i><span slot="title">{{ child.name }}</span>
                            </el-menu-item>
                        </el-submenu>
                        <el-menu-item v-if="!item.children" :index="item.path" :key="item.name">
                            <i :class="item.icon"></i>
                            <span slot="title">{{ item.name }}</span>
                        </el-menu-item>
                    </template>
                </el-menu>

            </aside>
            <section class="content-container" style="background-color: #f9fafb; height: 100%">
                <div class="grid-content bg-purple-light h-100">
                    <el-col :span="24" class="breadcrumb-container">
                        <div class="d-flex justify-content-between">
                            <strong class="title">{{ $route.name }}</strong>
                            <announcement></announcement>
                        </div>
                    </el-col>
                    <el-col :span="24" class="content-wrapper"
                            style="padding-top: 5px;height: calc(100% - 30px);overflow: auto">
                        <keep-alive>
                            <router-view class="h-100" :key="pageKey"
                                         v-if="$route.meta && $route.meta.keepAlive"></router-view>
                        </keep-alive>
                        <router-view class="h-100" :key="pageKey"
                                     v-if="!($route.meta && $route.meta.keepAlive)"></router-view>
                    </el-col>
                </div>
            </section>
        </el-col>
    </el-row>
</template>h

<script>

import Home from "../../router/home";
import {mapState, mapMutations} from "vuex";
import mobile from "../../common/mobile-input"
import Announcement from "@/components/announcememt";

export default {
    components: {Announcement},
    data() {
        return {
            collapsed: false,
            form: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: '',
            },
        }

    },
    created() {
        if (mobile()) this.collapsed = true;
    },
    computed: {
        ...mapState(['user', 'preset']),
        logo() {
            return '/assets/' + this.$organization.org + '/images/logo.png';
        },
        menu() {
            let menu = [];
            const vm = this;
            const level = parseInt(this.user.role_level);
            Home.forEach(function (item) {
                let can = true;
                let wide = false;
                if (item.meta) {
                    if (item.meta.level) { //判断级别够不够
                        can = item.meta.level <= level;
                        if (!can && item.meta.position) {
                            can = (vm.user.position == item.meta.position);
                        }
                    }
                    if (item.meta.wide) wide = item.meta.wide;
                }
                if (!item.hidden && can && !wide) {
                    const children = item.children;
                    if (children.length > 1) {  // 多个子项
                        let sub_menu = [];
                        children.forEach(function (child) {
                            let can = true;
                            if (child.meta && child.meta.level) { //子项也有权限
                                can = child.meta.level <= level;
                            }
                            const hidden = child.meta && (child.meta.hidden || child.meta.wide);
                            if (can && !hidden) { //子项隐藏
                                sub_menu.push(child);
                            }
                        });
                        menu.push({icon: item.icon, name: item.name, children: sub_menu});

                    } else if (children.length === 1) { //单个子目录
                        const child = children[0];
                        menu.push(Object.assign({icon: item.icon, children: false}, child))
                    }
                }
            });

            return menu;
        },
        pageKey() {
            if (this.$route.meta) {
                if (this.$route.meta.model) return this.$route.meta.model;
                else return this.$route.name;
            }
            return '';
        }
    },
    methods: {
        ...mapMutations('user', ['clear']),

        //折叠导航栏
        collapse: function () {
            this.collapsed = !this.collapsed;
        },
        handleSelect() {
            this.collapsed = true;
        },
    },
    mounted() {
    },
}

</script>

<style lang="scss">
$color-header: white;
$color-aside: #f7f8fa;
$color-logo: #455A64;

.mobile-home-wrapper {
    height: 100%;

    .home-header {
        height: 49px;
        //background: $color-header;
        background: url("../../assets/images/mybg.png") #f7f7f7;
        box-shadow: 0 2px 2px rgba(0, 0, 0, .05), 0 1px 0 rgba(0, 0, 0, .05);
        color: #fff;

        display: block;
        margin: 0;
        padding: 0 13px 0 0;
        position: relative;
        z-index: 905;
        overflow: hidden;

        .title {
            display: inline-block;
            vertical-align: middle;
            height: 49px;
            float: left;
            padding-left: 20px;
        }

        .userinfo {
            text-align: right;
            padding-right: 5px;
            float: right;

            .userinfo-image {
                cursor: pointer;
                display: inline-block;
                overflow: hidden;
                width: 30px;
                height: 30px;
                margin-top: 10px;
                border-radius: 100px;

                background: url("../../assets/images/avatar.png") no-repeat center top;
                background-size: cover;
            }

            .userinfo-name {
                display: inline-block;
                color: #606266;
                line-height: 49px;
                font-size: 1em;
                float: right;
                margin-left: 10px;
            }
        }

        .logo {
            line-height: 49px;
            font-size: large;
            color: #333;


                img {
                    height: 40px;
                }

        }

        .logo-width {
            width: 229px;
        }

        .logo-collapse-width {
            width: 0;
        }

        .title {
            .tools {
                float: left;
                height: 49px;
                line-height: 53px;
                cursor: pointer;
                margin-left: -10px;
                margin-right: 10px;

                i {
                    color: $color-logo;
                    font-size: 20px;

                    &:hover {
                        color: #3e9fff;
                    }
                }
            }

            .breadcrumb-inner {
                line-height: 49px;
            }
        }
    }

    .main {
        display: flex;

        position: absolute;
        top: 49px;
        bottom: 0;
        overflow: hidden;

        aside {
            flex: 0 0 230px;
            width: 230px;
            display: block;
            background-color: $color-aside;
            overflow: hidden;
            transition: all 0.2s ease-in;
            -webkit-overflow-scrolling: touch;
            min-height: 100%;

            .el-menu {
                height: 100%;
            }

            .collapsed {
                width: 64px;

                .item {
                    position: relative;
                }

                .submenu {
                    position: absolute;
                    top: 0;
                    left: 64px;
                    z-index: 99999;
                    height: auto;
                    display: none;
                }

            }
        }


        .menu-collapsed {
            flex: 0 0 0;
            width: 0;
        }

        .menu-expanded {
            flex: 0 0 230px;
            width: 230px;
        }

        .content-container {
            flex: 1;
            overflow-y: auto;
            padding: 10px;
            -webkit-overflow-scrolling: touch;


            .breadcrumb-container {
                //margin-bottom: 15px;
                .title {
                    width: 200px;
                    float: left;
                    color: #475669;
                    font-size: 20px;
                }
            }

            .content-wrapper {
                box-sizing: border-box;
                padding-top: 20px;
            }
        }
    }
}

.el-menu-vertical-demo {

    &.el-menu {
        background-color: $color-aside;

        .el-submenu__title:hover {
            background-color: #455a64;
            color: white;
            font-weight: bold;

            i {
                color: white;
                font-weight: bold;
            }
        }

        .el-submenu__title {
            color: #dde1ec;

            i {
                color: #bbc0cf;
            }
        }

        .el-menu-item {
            color: #dde1ec;

            i {
                color: #bbc0cf;
            }

            &.is-active {
                color: yellow;

                i {
                    color: yellow;
                }
            }

            &:focus {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }

        .el-menu-item:hover {
            background-color: #455a64;
            color: white;
            font-weight: bold;

            i {
                color: white;
                font-weight: bold;
            }
        }

        .el-submenu .el-menu {
            background-color: #363a4b;
        }
    }

    &:not(.el-menu--collapse) {
        width: 230px;
    }
}

</style>
